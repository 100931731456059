import React from "react";
import { Link } from "react-router-dom";

function SideMenu() {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="h-full w-full flex-col justify-between  bg-white hidden lg:flex">
      <div className="px-4 py-6">
        <nav aria-label="Main Nav" className="mt-6 flex flex-col space-y-1">
          <Link
            to="/projects"
            className="flex items-center gap-2 rounded-lg hover:bg-gray-100 px-4 py-2 text-gray-700"
          >
            <img
              alt="dashboard-icon"
              src={require("../assets/dashboard-icon.png")}
            />
            <span className="text-sm font-medium"> Projects </span>
          </Link>

          <Link
            to="/users"
            className="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <img alt="users-icon" src={require("../assets/supplier-icon.png")} />
            <span className="text-sm font-medium"> Users</span>
          </Link>

          <Link 
            to="/signs"
            className="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <div className="flex items-center gap-2">
              <img
                className="w-5"
                alt="store-icon"
                src={require("../assets/road.png")}
              />
              <span className="text-sm font-medium"> Signs </span>
            </div>
          </Link>
        </nav>
      </div>

      <div className="sticky inset-x-0 bottom-0 border-t border-gray-100">
        <div className="flex items-center gap-2 bg-white p-4 hover:bg-gray-50">
          <img
            alt="Profile"
            src={require("../assets/user.png")}
            className="h-10 w-10 rounded-full object-cover"
          />

          <div>
            <p className="text-xs">
              <strong className="block font-medium">
                {user.name}
              </strong>

              <span> {user.email} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
