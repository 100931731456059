import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Map from "react-map-gl";
import config from "../config";

export default function PrintProject({
  mapRef,
  divRef,
  project,
  setLock,
  setViewport,
  mapModal,
  setMapModal,
  setMapModalImg,
}) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    projectName: "",
    projectNumber: "",
    note: "",
  });

  const dataRef = useRef<HTMLDivElement>(null);
  const miniMapRef = useRef<HTMLDivElement>(null);
  const currentMap = mapRef.current?.getMap();
  const mapCenter = currentMap?.getCenter();
  const mapZoom = currentMap?.getZoom();

  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const handleInputChange = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  const handlePrintPNG = useCallback(() => {
    if (!mapRef || !divRef) {
      return;
    }

    setLock(true);
    const mapboxControlContainerAll = document.querySelectorAll(
      ".mapboxgl-control-container"
    );
    mapboxControlContainerAll.forEach((mapboxControlContainer) => {
      (mapboxControlContainer as any).style.display = "none";
    });

    const map = mapRef.current?.getMap();
    const MapCenter = map?.getCenter();
    const MapZoom = map?.getZoom();

    setTimeout(() => {
      map.flyTo({
        center: MapCenter,
        zoom: MapZoom,
        essential: false, // This animation is considered essential with respect to prefers-reduced-motion
      });

      map?.once("render", () => {
        document.getElementById("maplogo").style.display = "";
        document.getElementById("ChangeBaseMap").style.display = "none";

        html2canvas(divRef.current as HTMLElement, { scale: 5 }).then(
          (canvas) => {
            document.getElementById("maplogo").style.display = "none";

            const ctx = canvas.getContext("2d");
            const logo = new Image();
            logo.src =
              "https://unsplash.com/photos/silver-mercedes-benz-emblem-on-blue-surface-5MlBMYDsGBY"; // Replace with the actual path to your logo file

            const url = canvas.toDataURL();

            var link = document.createElement("a");
            link.download = project.name + ".png";
            link.href = url;
            link.click();
            setLock(false);
            mapboxControlContainerAll.forEach((mapboxControlContainer) => {
              (mapboxControlContainer as any).style.display = "block";
            });
          }
        );
      });
    }, 500);
  }, [divRef, mapRef, project]);

  const handlePrintPDF = useCallback(() => {
    if (!mapRef || !divRef) {
      return;
    }

    setLock(true);
    const mapboxControlContainerAll = document.querySelectorAll(
      ".mapboxgl-control-container"
    );
    mapboxControlContainerAll.forEach((mapboxControlContainer) => {
      (mapboxControlContainer as any).style.display = "none";
    });

    const map = mapRef.current?.getMap();
    const MapCenter = map?.getCenter();
    const MapZoom = map?.getZoom();

    setTimeout(() => {
      map.flyTo({
        center: MapCenter,
        zoom: MapZoom,
        essential: false, // This animation is considered essential with respect to prefers-reduced-motion
      });

      map?.once("render", async () => {
        document.getElementById("maplogo").style.display = "";
        document.getElementById("ChangeBaseMap").style.display = "none";

        const canvas = await html2canvas(divRef.current as HTMLElement, {
          scale: 4,
        });
        const dataCanvas = await html2canvas(dataRef.current as HTMLElement, {
          scale: 2,
        });
        const maniMapCanvas = await html2canvas(
          miniMapRef.current as HTMLElement,
          {
            scale: 1,
          }
        );

        document.getElementById("maplogo").style.display = "none";

        const imgData = canvas.toDataURL("image/png");
        const imgData2 = dataCanvas.toDataURL("image/png");
        const imgData3 = maniMapCanvas.toDataURL("image/png");

        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: "a4", // Setting to A4 format
        });

        const pageWidth = 630; // A4 width in points

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const pdfWidth = pageWidth * 1; // 70% of page width
        const scale = pdfWidth / canvasWidth; // Scale the canvas proportionally
        const pdfHeight = canvasHeight * scale; // Maintain aspect ratio
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

        const canvasWidth2 = dataCanvas.width;
        const canvasHeight2 = dataCanvas.height;

        const pdfWidth2 = pageWidth * 0.3; // 70% of page width
        const scale2 = pdfWidth2 / canvasWidth2; // Scale the canvas proportionally
        const pdfHeight2 = canvasHeight2 * scale2; // Maintain aspect ratio

        pdf.addImage(
          imgData3,
          "PNG",
          0,
          pdfHeight,
          pdfWidth2 - 4,
          (pdfWidth2 - 4) * (9 / 16)
        );

        pdf.addImage(
          imgData2,
          "PNG",
          pdfWidth2,
          pdfHeight,
          pdfWidth2 - 4,
          pdfHeight2
        );

        pdf.save(`${project.name}.pdf`);

        setLock(false);
        mapboxControlContainerAll.forEach((mapboxControlContainer) => {
          (mapboxControlContainer as any).style.display = "block";
        });
        toggleForm();
      });
    }, 500);
  }, [divRef, mapRef, project]);

  const handleGetPNG = useCallback(() => {
    if (!mapRef || !divRef) {
      return;
    }

    setLock(true);
    const mapboxControlContainerAll = document.querySelectorAll(
      ".mapboxgl-control-container"
    );
    mapboxControlContainerAll.forEach((mapboxControlContainer) => {
      (mapboxControlContainer as any).style.display = "none";
    });

    const map = mapRef.current?.getMap();
    const MapCenter = map?.getCenter();
    const MapZoom = map?.getZoom();

    setTimeout(() => {
      map.flyTo({
        center: MapCenter,
        zoom: MapZoom,
        essential: false, // This animation is considered essential with respect to prefers-reduced-motion
      });

      map?.once("render", () => {
        document.getElementById("maplogo").style.display = "";
        document.getElementById("ChangeBaseMap").style.display = "none";

        html2canvas(divRef.current as HTMLElement, { scale: 5 }).then(
          (canvas) => {
            document.getElementById("maplogo").style.display = "none";

            const ctx = canvas.getContext("2d");
            const logo = new Image();
            logo.src =
              "https://unsplash.com/photos/silver-mercedes-benz-emblem-on-blue-surface-5MlBMYDsGBY"; // Replace with the actual path to your logo file

            const url = canvas.toDataURL();

            setMapModalImg(url);
            setLock(false);
            mapboxControlContainerAll.forEach((mapboxControlContainer) => {
              (mapboxControlContainer as any).style.display = "block";
            });
          }
        );
      });
    }, 500);
  }, [divRef, mapRef, project]);

  return (
    <Fragment>
      {mapCenter && (
        <div
          ref={miniMapRef}
          className="absolute -left-full top-0 z-50 w-80 aspect-video"
        >
          <Map
            viewState={{
              zoom: 12,
              longitude: mapCenter.lng,
              latitude: mapCenter.lat,
              bearing: 1,
              pitch: 1,
              padding: { top: 0, left: 0, right: 0, bottom: 0 },
              width: 320,
              height: 320,
            }}
            mapboxAccessToken={config.mapBoxToken}
            mapStyle={config.mapNormalView}
          ></Map>
          <div className="w-12 h-12 border-2 border-red-600 rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
      )}

      <button
        onClick={handleGetPNG}
        className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
      >
        Screenshot
      </button>
      <Menu className="relative" as="div">
        <Menu.Button>
          <button className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400">
            Print
          </button>
        </Menu.Button>
        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <p
                onClick={toggleForm}
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                )}
              >
                PDF
              </p>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <p
                onClick={handlePrintPNG}
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "block px-4 py-2 text-sm text-gray-700  cursor-pointer"
                )}
              >
                PNG
              </p>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <Transition.Root show={isFormOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={toggleForm}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <form className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
                        <Dialog.Title
                          as="h3"
                          className="text-lg  py-4 font-semibold leading-6 text-gray-900 flex items-center"
                        >
                          <div className="mx-auto flex h-12 items-center justify-center rounded-full bg-blue-100 sm:h-10 sm:w-10 mr-4">
                            <PlusIcon
                              className="h-6 w-6 text-blue-400"
                              aria-hidden="true"
                            />
                          </div>
                          <span className="grow">Print</span>
                        </Dialog.Title>
                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="sign-name"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Project Name
                            </label>
                            <input
                              required
                              type="text"
                              name="projectName"
                              id="sign-name"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Project Name"
                              value={formData?.projectName}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="sign-number"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Project Number
                            </label>
                            <input
                              required
                              type="text"
                              name="projectNumber"
                              id="sign-number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Project Number"
                              value={formData?.projectNumber}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="sign-description"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Note
                            </label>
                            <textarea
                              required
                              name="note"
                              id="sign-description"
                              value={formData?.note}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Note..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handlePrintPDF();
                      }}
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    >
                      Print
                    </button>
                    <button
                      onClick={toggleForm}
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </form>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className="fixed top-0 -left-full bg-white px-2 pt-0 pb-3 border border-gray-800 flex flex-col gap-1 w-80 text-sm"
        ref={dataRef}
      >
        <p>
          <span className="font-medium">Project Name:</span>{" "}
          {formData.projectName}
        </p>
        <p>
          <span className="font-medium">Project Number:</span>{" "}
          {formData.projectNumber}
        </p>
        <p>
          <span className="font-medium">Note:</span> {formData.note}
        </p>
      </div>
    </Fragment>
  );
}
