import { Fragment, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import AuthContext from "../AuthContext";
import Axios from "../api/api";
import { toast } from "react-toastify";

export default function AddUser({
  hideUserModal,
  handlePageUpdate,
  isEdit,
  currentUser,
}) {
  const [user, setUser] = useState({
    id: currentUser ? currentUser._id : "",
    name: currentUser ? currentUser.name : "",
    email: currentUser ? currentUser.email : "",
    password: currentUser ? currentUser.password : "",
    phoneNumber: currentUser ? currentUser.phoneNumber ?? "" : "",
    limit: currentUser ? currentUser.limit ?? 0 : "",
  });

  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  // Handling Input Change for input fields
  const handleInputChange = (key, value) => {
    setUser({ ...user, [key]: value });
  };

  // POST Data
  const saveUser = () => {
    if (user.name === "") return toast("Please input name", { type: "info" });
    if (user.email === "") return toast("Please input email", { type: "info" });
    if (user.password === "")
      return toast("Please input password", { type: "info" });

    let url = "";
    if (isEdit) url = "/api/users/edit";
    else url = "/api/users/add";

    Axios(auth)
      .post(url, user)
      .then((result) => {
        handlePageUpdate();
        hideUserModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    // Modal
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg  py-4 font-semibold leading-6 text-gray-900 flex items-center"
                      >
                        <div className="mx-auto flex h-12 items-center justify-center rounded-full bg-blue-100 sm:h-10 sm:w-10 mr-4">
                          <PlusIcon
                            className="h-6 w-6 text-blue-400"
                            aria-hidden="true"
                          />
                        </div>
                        <span className="grow">
                          {isEdit ? "Edit" : "Add"} User
                        </span>
                      </Dialog.Title>
                      <form action="#">
                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="user-name"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              User Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="user-name"
                              value={user.name}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Name"
                            />
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="user-email"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="user-email"
                              value={user.email}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Email"
                            />
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="user-password"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Password
                            </label>
                            <input
                              type="text"
                              name="password"
                              id="user-password"
                              value={user.password}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Password"
                            />
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="user-phoneNumber"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              name="phoneNumber"
                              id="user-phoneNumber"
                              value={user.phoneNumber}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Phone Number"
                            />
                          </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                          <div>
                            <label
                              htmlFor="user-limit"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Limit
                            </label>
                            <input
                              type="number"
                              name="limit"
                              id="user-limit"
                              value={user.limit}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Limit"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={saveUser}
                  >
                    {isEdit ? "Save" : "Add"} User
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => hideUserModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
