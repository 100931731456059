import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useNavigate, useParams } from "react-router";
import AuthContext from "../AuthContext";

import Axios from "../api/api";
import Map, { Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { v4 as uuidv4 } from "uuid";
import CustomMarker from "./CustomMarker";
import GeocoderControl from "./geocoder-controlNew";
import "../index.css";
import MapLineDrawer from "../components/MapLineDrawer";

const mapBoxToken =
  "pk.eyJ1IjoiaG50MTk4OCIsImEiOiJjbHN0MWxxenYwbjMwMmttdWUwbXhiZmNzIn0.yRXFE61Sqto5Zd8mrMfPUw";

const buttonStyles = {
  padding: "8px",
  backgroundColor: "white",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
  fontWeight: "bold",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
};

function ShareProject() {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const mapRef = useRef<any | null>(null);
  const markersRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  const [colorModal, showColorModal] = useState(false);
  const [project, setProject] = useState<any>({});
  const [items, setItems] = useState<any[]>([]);
  const [updatePage, setUpdatePage] = useState(false);
  const [lock, setLock] = useState(false);
  const [drawLine, setDrawLine] = useState(false);
  const [drawLineColor, setDrawLineColor] = useState("red");
  const [total, setTotal] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [locationSearch, setLocationSearch] = useState("");
  const [locationCity, setLocationCity] = useState("");
  const [lng, setLng] = useState(12.5683);
  const [lat, setLat] = useState(55.6761);
  const [mapZoom, setMapZoom] = useState(15);
  const [lines, setLines] = useState([]);
  const [clickCoordinates, setClickCoordinates] = useState({
    lng: null,
    lat: null,
  });
  const [hoverCoordinates, setHoverCoordinates] = useState({
    lng: null,
    lat: null,
  });
  const NormalView = "mapbox://styles/mapbox/streets-v9";
  const SatelliteView = "mapbox://styles/mapbox/satellite-v9";
  const [mapStyle, setMapStyle] = useState(SatelliteView);

  const [viewport, setViewport] = useState({
    width: 800,
    height: 600,
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 10,
    bearing: 0,
  });

  const [popupInfo, setPopupInfo] = useState(null);

  const fetchProjectData = useCallback(() => {
    Axios(auth)
      .get(`/api/projects/get/${id}`)
      .then((resp) => {
        var data = resp.data;
        setProject(data);
        if (data.items) {
          setItems(data.items);
          setZoom(data.zoom || 1);
          setLat(data.lat || 55.6761);
          setLng(data.lng || 12.5683);
          setMapZoom(data.mapZoom || 15);
          setTotal(data.items.length);
          setLines(data?.lines || []);
          mapRef.current?.setCenter({
            lat: data.lat || 55.6761,
            lng: data.lng || 12.5683,
          });
          mapRef.current?.setZoom(data.mapZoom || 15);
        }
      })
      .catch((err) => {});
  }, [id, auth]);

  useEffect(() => {
    fetchProjectData();
  }, [fetchProjectData]);

  useEffect(() => {
    setUpdatePage(!updatePage);
  }, [items]);

  useEffect(() => {
    if (lat !== null && lng !== null) mapRef.current?.setCenter({ lat, lng });
    if (mapZoom !== null) mapRef.current?.setZoom(mapZoom);

    console.log(lat, lng, mapZoom, mapRef);
  }, [mapZoom, lat, lng, mapRef]);

  const handleOnDragMarker = useCallback((event: any, itemId: string) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            position: { long: event.lngLat.lng, lat: event.lngLat.lat },
          };
        }
        return item;
      });

      return updatedItems;
    });
  }, []);

  const markers = useMemo(() => {
    return items.map((item, index) => {
      return (
        <CustomMarker
          key={item.id}
          item={item}
          isLock={lock}
          setLock={setLock}
          viewport={viewport}
          onDragEnd={handleOnDragMarker}
          onClick={(markerId: string) => {
            const updatedList = items.map((item) => {
              if (item.id === markerId) {
                return { ...item, active: true };
              } else {
                return { ...item, active: false };
              }
            });
            if (item.type === "text") {
              setPopupInfo(item);
            }

            setItems(updatedList);
          }}
          onChange={(newText: string) => {
            setItems((prevItems) => {
              let newItems = [...prevItems];
              newItems[index].data.text = newText;
              return newItems;
            });
          }}
          onCopy={(item, itemTransform) => {
            setItems((prevItems) => {
              let newItems = [...prevItems];
              const prevItem = newItems.find((_item) => _item.id === item.id);
              if (prevItem) {
                prevItem.active = false;
              }
              newItems.push({
                ...item,
                id: uuidv4(),
                style: itemTransform,
                position: {
                  long: item.position.long + 0.00007,
                  lat: item.position.lat - 0.00007,
                },
                active: true,
              });
              return newItems;
            });
          }}
        />
      );
    });
  }, [handleOnDragMarker, items, lock, viewport]);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-full h-full md:w-3/5 md:h-3/4 border-4 md:rounded-lg overflow-hidden border-gray-800">
        <div className="h-16 bg-gray-800 w-full flex items-center px-6">
          <img
            className="h-8"
            src={require("../assets/logo.png")}
            alt="VSKETCH"
          />
        </div>
        <Map
          ref={mapRef}
          initialViewState={{
            longitude: 10.915721,
            latitude: 55.897377,
            zoom: 6.5,
          }}
          mapStyle={mapStyle}
          onZoom={(e) => {
            const zoom = e.viewState.zoom;
            setViewport((prev) => {
              return { ...prev, zoom };
            });
          }}
          onRotate={(e) => {
            const bearing = e.viewState.bearing;
            setViewport((prev) => {
              return { ...prev, bearing };
            });
          }}
          mapboxAccessToken={mapBoxToken}
          trackResize={true}
          styleDiffing={true}
          onClick={(event) => {
            setClickCoordinates(event.lngLat);
            setLock(true);

            const features = mapRef.current.queryRenderedFeatures(event.point);

            if (features.length > 0) {
              const clickedFeature = features[0];
              const selectedFeatureId = clickedFeature?.source.split("-")[1];

              setLines(
                lines.map((line) =>
                  line.id == selectedFeatureId
                    ? { ...line, selected: true }
                    : { ...line, selected: false }
                )
              );
            } else {
              setLines(lines.map((line) => ({ ...line, selected: false })));
            }
          }}
          onMouseMove={(event) => setHoverCoordinates(event.lngLat)}
          onDblClick={(event) => {
            if (
              clickCoordinates?.lng !== null &&
              clickCoordinates?.lat !== null
            ) {
              event.preventDefault();
              setClickCoordinates({ lng: null, lat: null });
            }
          }}
        >
          <MapLineDrawer
            hideControls
            lines={lines}
            setLines={setLines}
            viewport={viewport}
            clickCoordinates={clickCoordinates}
            hoverCoordinates={hoverCoordinates}
          />
          <GeocoderControl
            marker={false}
            mapboxAccessToken={mapBoxToken}
            position="top-left"
          />
          <div
            style={{ position: "absolute", top: 8, right: 8 }}
            id={"ChangeBaseMap"}
          >
            <button
              style={buttonStyles}
              onClick={() => {
                if (mapStyle === SatelliteView) {
                  setMapStyle(NormalView);
                } else {
                  setMapStyle(SatelliteView);
                }
              }}
            >
              {mapStyle === SatelliteView ? (
                <img
                  alt="users-icon"
                  src={require("../assets/treasure-map.png")}
                />
              ) : (
                <img
                  alt="users-icon"
                  src={require("../assets/treasure-mapS.png")}
                />
              )}
            </button>
          </div>
          {markers}
          {popupInfo && (
            <Popup
              anchor="top"
              longitude={Number(popupInfo.position.long)}
              latitude={Number(popupInfo.position.lat)}
              onClose={() => setPopupInfo(null)}
            >
              <textarea
                defaultValue={popupInfo.data.text}
                onChange={(e) => {
                  const newText = e.target.value;
                  setItems((prevItems) => {
                    let newItems = [...prevItems];
                    const targetItemIndex = newItems.findIndex(
                      (_item) => _item.id === popupInfo.id
                    );
                    newItems[targetItemIndex].data.text = newText;
                    return newItems;
                  });
                }}
              ></textarea>
            </Popup>
          )}
        </Map>
      </div>
    </div>
  );
}

export default ShareProject;
