import { Resizable } from "re-resizable";
import { useState } from "react";
import Draggable from "react-draggable";

const DragAndResizable = ({ backgroundImage }) => {
  const [disableDragging, setDisabledDragging] = useState(true);

  return (
    <Draggable disabled={disableDragging}>
      <Resizable
        handleWrapperStyle={{ width: "auto", height: "auto" }}
        defaultSize={{
          width: 100,
        }}
        lockAspectRatio={true}
      >
        <img className="w-full h-auto" src={backgroundImage} />
        <div
          className="absolute top-0 left-0 w-full h-full p-2"
          style={{ cursor: disableDragging ? "auto" : "grab" }}
        >
          <div
            className="w-full h-full"
            onMouseEnter={() => setDisabledDragging(false)}
            onMouseLeave={() => setDisabledDragging(true)}
          />
        </div>
      </Resizable>
    </Draggable>
  );
};

export default DragAndResizable;
