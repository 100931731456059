import React, { useState, useEffect, useContext } from "react";
import AddUser from "../components/AddUser";
import AuthContext from "../AuthContext";
import Axios from "../api/api";
import { toast } from "react-toastify";

function Users() {
  const [userModalSetting, setUserModalSetting] = useState({
    isEdit: false,
    showModal: false,
    currentUser: null,
  });
  const [users, setAllUsersData] = useState([]);
  const [updatePage, setUpdatePage] = useState(true);
  const auth = useContext(AuthContext);

  useEffect(() => {
    fetchUsersData();
  }, [updatePage]);

  // Fetching Data of All Users
  const fetchUsersData = () => {
    Axios(auth)
      .get("/api/users/get")
      .then((resp) => {
        setAllUsersData(resp.data);
      })
      .catch((err) => {
        setAllUsersData([]);
        console.log(err);
      });
  };

  // Modal for User Add
  const addUserModalSetting = () => {
    setUserModalSetting({
      isEdit: false,
      showModal: true,
      currentUser: null,
    });
  };

  const hideUserModal = () => {
    setUserModalSetting({ ...userModalSetting, showModal: false });
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  // Handle Page Update
  const handleClickDelete = (id) => {
    if (window.confirm("Click OK to delete the user")) {
      Axios(auth)
        .delete(`/api/users/delete/` + id)
        .then((res) => {
          if (res.status == 200) {
            toast("The user is deleted", { type: "success" });
            handlePageUpdate();
          } else {
            toast("Operation failed", { type: "error" });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // Modal for User Edit
  const handleClickEdit = (user) => {
    setUserModalSetting({
      isEdit: true,
      showModal: true,
      currentUser: user,
    });
  };

  return (
    <>
      <div className=" flex flex-col gap-5 w-11/12">
        {userModalSetting.showModal && (
          <AddUser
            hideUserModal={hideUserModal}
            handlePageUpdate={handlePageUpdate}
            isEdit={userModalSetting.isEdit}
            currentUser={userModalSetting.currentUser}
          />
        )}
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 p-5">
          <div className="flex justify-between">
            <div className="flex gap-4 justify-center items-center px-3">
              <span className="font-bold">Users</span>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-sm rounded"
                onClick={addUserModalSetting}
              >
                Add User
              </button>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm mt-2">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  User Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Email
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Phone Number
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Limit
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {users.map((user, index) => {
                return (
                  <tr key={user._id}>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {user.name}
                    </td>
                    <td className="px-4 py-2 text-gray-700">{user.email}</td>
                    <td className="px-4 py-2 text-gray-700">
                      {user.phoneNumber}
                    </td>
                    <td className="px-4 py-2 text-gray-700">{user.limit}</td>
                    <td className="px-4 py-2 text-gray-700">
                      <div className="flex items-center">
                        <img
                          src={require("../assets/editing.png")}
                          className="w-7 cursor-pointer mr-2"
                          alt="edit"
                          onClick={() => handleClickEdit(user)}
                        />
                        {user.role === "guest" ? (
                          <img
                            src={require("../assets/delete.png")}
                            className="w-7 cursor-pointer"
                            alt="delete"
                            onClick={() => handleClickDelete(user._id)}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Users;
