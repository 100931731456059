import React, { useRef, useState } from "react";
import { ScreenCapture } from "react-screen-capture";

const SnippingTool = ({
  children,
  mapModal,
  setMapModal,
  mapModalImg,
  setMapModalImg,
  projectName,
}) => {
  const handleScreenCapture = (source) => {
    setMapModal(false);
    setMapModalImg("");

    const screenCaptureSource = source;
    const downloadLink = document.createElement("a");
    const fileName = projectName + ".png";

    downloadLink.href = screenCaptureSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  // return children;
  return (
    <ScreenCapture onEndCapture={handleScreenCapture}>
      {({ onStartCapture }) => (
        <>
          {mapModalImg && (
            <div className="fixed w-full h-screen top-0 left-0 bg-white grid grid-rows-[auto_1fr] py-20">
              <div className="p-4 flex gap-2">
                <button
                  onClick={onStartCapture}
                  className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                >
                  Start
                </button>
                <button
                  onClick={() => setMapModalImg("")}
                  className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                >
                  Cancel
                </button>
              </div>
              <div className="p-4 overflow-hidden">
                <img
                  src={mapModalImg}
                  className="h-full w-full object-contain object-center"
                />
              </div>
            </div>
          )}
          {children}
        </>
      )}
    </ScreenCapture>
  );
};

export default SnippingTool;
