import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";

import Axios from "../api/api";
import AuthContext from "../AuthContext";

const shapes = [
  { name: "square", clipPath: "inset(0 0 0 0)" },
  { name: "circle", clipPath: "circle(50% at 50% 50%)" },
  { name: "triangle", clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)" },
  {
    name: "arrow left",
    clipPath:
      "polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)",
  },
  {
    name: "arrow right",
    clipPath:
      "polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)",
  },
];

export default function EditHatch({ onClose, onSave, open, sign }) {
  const [shape, setShape] = useState(shapes[0]);

  const editedDivRef = useRef(null);
  const auth = useContext(AuthContext);

  const handleSave = async () => {
    console.log(editedDivRef.current);
    domtoimage.toPng(editedDivRef.current).then((dataUrl) => {
      Axios(auth)
        .post("/api/signs/private/add", {
          name: sign.name + " - " + shape.name,
          image: dataUrl,
          type: sign.type,
        })
        .then(() => {
          onSave();
          handleClose();
        })
        .catch((err) => console.log(err));
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg  py-4 font-semibold leading-6 text-gray-900 flex items-center"
                      >
                        <div className="mx-auto flex h-12 items-center justify-center rounded-full bg-blue-100 sm:h-10 sm:w-10 mr-4">
                          <PlusIcon
                            className="h-6 w-6 text-blue-400"
                            aria-hidden="true"
                          />
                        </div>
                        <span className="grow">Edit Image</span>
                      </Dialog.Title>
                      <div className="w-full flex justify-center">
                        <div
                          ref={editedDivRef}
                          style={{
                            clipPath: shape.clipPath,
                            backgroundImage: `url(${sign?.image})`,
                          }}
                          className="relative overflow-hidden w-full max-w-64 aspect-square bg-transparent"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-4 flex gap-2 flex-col">
                  <div className="flex flex-row gap-4 flex-wrap items-center">
                    {shapes.map((item) => (
                      <label for="square">
                        <input
                          checked={item.name === shape.name}
                          onChange={(e) => setShape(item)}
                          type="radio"
                          name="shape"
                          className="mr-2"
                        />
                        {item.name}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={handleSave}
                  >
                    Save Image
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
