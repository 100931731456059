import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";

import Layout from "./components/Layout";
import NoPageFound from "./pages/NoPageFound";
import Login from "./pages/Login";
import AuthContext from "./AuthContext";
import ProtectedWrapper from "./ProtectedWrapper";
import Signs from "./pages/Signs";
import Users from "./pages/Users";
import Projects from "./pages/Projects";
import ProjectDetail from "./pages/ProjectDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareProject from "./pages/ShareProject";
// import "./index.css";

const App = () => {
  let myLoginUser = JSON.parse(localStorage.getItem("user") as string);
  const [user, setUser] = useState(myLoginUser ? myLoginUser : {});

  const signin = (newUser: any, callback: any) => {
    setUser(newUser);
    callback();
  };

  const signout = () => {
    setUser(null);
  };

  const updateLimit = (limit: any) => {
    let newUser = { ...user, limit: limit };
    setUser(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  let value = { user, signin, signout, updateLimit, userData: user };

  return (
    <AuthContext.Provider value={value as any}>
      <ToastContainer position="top-center" />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/share/projects/:id" element={<ShareProject />} />
          <Route
            path="/"
            element={
              <ProtectedWrapper>
                <Layout />
              </ProtectedWrapper>
            }
          >
            <Route path="/projects/:id" element={<ProjectDetail />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/users" element={<Users />} />
            <Route path="/signs" element={<Signs />} />
            <Route path="/" element={<Projects />} />
          </Route>
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
