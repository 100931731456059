import React from "react";

const Spinner = ({ className }) => {
  return (
    <div className="flex items-center justify-center h-6 overflow-hidden">
      <div
        className={
          "h-5 w-5 animate-spin rounded-full border-2 border-solid border-gray-800 border-t-transparent " +
          className
        }
      ></div>
    </div>
  );
};

export default Spinner;
