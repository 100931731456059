// @ts-ignore

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Map, { Popup } from "react-map-gl";
import AuthContext from "../AuthContext";
import StickerList from "../components/StickerList";
import SelectColor from "../components/SelectColor";
import Axios from "../api/api";
import CustomMarker from "./CustomMarker";
import GeocoderControl from "./geocoder-controlNew";
import MapLineDrawer from "../components/MapLineDrawer";
import "mapbox-gl/dist/mapbox-gl.css";
import "../index.css";
import PrintProject from "../components/PrintProject";
import SnippingTool from "../components/SnippingTool";

import config from "../config";

const buttonStyles = {
  padding: "8px",
  backgroundColor: "white",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
  fontWeight: "bold",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
};

function ProjectDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const mapRef = useRef<any | null>(null);
  const markersRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  const [colorModal, showColorModal] = useState(false);
  const [project, setProject] = useState<any>({});
  const [items, setItems] = useState<any[]>([]);
  const [updatePage, setUpdatePage] = useState(false);
  const [lock, setLock] = useState(false);
  const [drawLine, setDrawLine] = useState(false);
  const [drawLineColor, setDrawLineColor] = useState("red");
  const [total, setTotal] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [locationSearch, setLocationSearch] = useState("");
  const [locationCity, setLocationCity] = useState("");
  const [lng, setLng] = useState(12.5683);
  const [lat, setLat] = useState(55.6761);
  const [mapZoom, setMapZoom] = useState(15);
  const [lines, setLines] = useState([]);
  const [clickCoordinates, setClickCoordinates] = useState({
    lng: null,
    lat: null,
  });
  const [hoverCoordinates, setHoverCoordinates] = useState({
    lng: null,
    lat: null,
  });
  const NormalView = "mapbox://styles/mapbox/streets-v9";
  const SatelliteView = "mapbox://styles/mapbox/satellite-v9";
  const [mapStyle, setMapStyle] = useState(SatelliteView);

  const [viewport, setViewport] = useState({
    width: 800,
    height: 600,
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 18,
    bearing: 0,
  });

  const [popupInfo, setPopupInfo] = useState(null);
  const [features, setFeatures] = useState({});

  const [lineDrawing, setLineDrawing] = useState({
    isDrawing: false,
    previousX: 0,
    previousY: 0,
    currentX: 0,
    currentY: 0,
  });

  const fetchProjectData = useCallback(() => {
    Axios(auth)
      .get(`/api/projects/get/${id}`)
      .then((resp) => {
        var data = resp.data;
        setProject(data);
        if (data.items) {
          setItems(data.items);
          setZoom(data.zoom || 1);
          setLat(data.lat || 55.6761);
          setLng(data.lng || 12.5683);
          setMapZoom(data.mapZoom || 15);
          setTotal(data.items.length);
          setLines(data?.lines || []);
          mapRef.current?.setCenter({
            lat: data.lat || 55.6761,
            lng: data.lng || 12.5683,
          });
          mapRef.current?.setZoom(data.mapZoom || 15);
        }
      })
      .catch((err) => {});
  }, [id, auth]);

  useEffect(() => {
    fetchProjectData();
  }, [fetchProjectData]);

  useEffect(() => {
    setUpdatePage(!updatePage);
  }, [items]);

  const onClickShare = () => {
    const shareLink =
      window.location.origin + "/share" + window.location.pathname;
    navigator.clipboard.writeText(shareLink);
    toast("Text copied to the clipboard", { type: "success" });
  };

  const addItem = useCallback(
    (type: string, data: any, style: string) => {
      const map = mapRef.current?.getMap();
      const center = map?.getCenter();

      if (center) {
        const newItem = {
          id: uuidv4(),
          type,
          data,
          active: true,
          style: style,
          index: total,
          position: { long: center.lng, lat: center.lat },
        };
        const newItems = items.map((item) => ({ ...item, active: false }));

        setTotal(total + 1);
        setItems([...newItems, newItem]);
      }
    },
    [total, items, mapRef]
  );

  const onClickAddSign = useCallback(
    (sign: any) => {
      const scrollEle = document.getElementById("project-panel")
        ?.parentNode as any;
      if (scrollEle) {
        addItem(
          "sign",
          {
            name: sign.name,
            image: sign.image,
          },
          `width:50px;`
        );
        setDrawLine(false);
      }
    },
    [addItem]
  );

  const onClickAddText = useCallback(() => {
    const scrollEle = (document as any).getElementById("project-panel")
      .parentNode as any;
    addItem(
      "text",
      { text: "Hello Text" },
      `transform:translate(${scrollEle.scrollLeft}px, ${scrollEle.scrollTop}px);`
    );
    setDrawLine(false);
  }, [addItem]);

  const onClickAddLine = useCallback(() => {
    showColorModal(true);
    setDrawLine(false);
  }, []);

  const onColorSelect = useCallback((clr: any) => {
    setDrawLineColor(clr.hex);
    setDrawLine(true);
    showColorModal(false);
  }, []);

  const handleMouseDown = useCallback(
    (e: any) => {
      if (!drawLine) return;
      e.preventDefault();
      setLineDrawing({
        ...lineDrawing,
        isDrawing: true,
        previousX: e.nativeEvent.offsetX,
        previousY: e.nativeEvent.offsetY,
        currentX: e.nativeEvent.offsetX,
        currentY: e.nativeEvent.offsetX,
      });
    },
    [drawLine, lineDrawing]
  );

  const handleMouseMove = useCallback(
    (e: any) => {
      if (!drawLine) return;
      if (!lineDrawing.isDrawing) return;
      e.preventDefault();
      setLineDrawing({
        ...lineDrawing,
        currentX: e.nativeEvent.offsetX,
        currentY: e.nativeEvent.offsetY,
      });
    },
    [drawLine, lineDrawing]
  );

  const handleMouseUp = useCallback(() => {
    if (!lineDrawing.isDrawing) return;
    setLineDrawing({
      ...lineDrawing,
      isDrawing: false,
    });
    setDrawLine(false);
    addItem(
      "line",
      {
        color: drawLineColor,
      },
      `
      left: ${lineDrawing.previousX}px;
      top: ${lineDrawing.previousY}px;
      width: ${lineDrawing.currentX - lineDrawing.previousX}px;
      height: ${lineDrawing.currentY - lineDrawing.previousY}px;
    `
    );
  }, [addItem, drawLineColor, lineDrawing]);

  const onClickDelete = useCallback(() => {
    if (window.confirm("Click OK to delete")) {
      setItems((prevItems) => prevItems.filter((item) => !item.active));
      setLines((prevItems) => prevItems.filter((item) => !item.selected));
    }
  }, [items]);

  const onClickZoomIn = useCallback(() => {
    setZoom(zoom + 0.1);
  }, [zoom]);

  const onClickZoomOut = useCallback(() => {
    setZoom(zoom > 0.5 ? zoom - 0.1 : zoom);
  }, [zoom]);

  const onClickLock = useCallback(() => {
    setLock((prev) => !prev);
    setDrawLine(false);
    setItems((prev) => prev.map((i) => ({ ...i, active: false })));
  }, []);

  const onClickSave = useCallback(() => {
    const map = mapRef.current?.getMap();
    const currentZoom = map?.getZoom();
    const currentCenter = map?.getCenter();

    var saveItems = items.map((item) => {
      var saveItem = {
        id: item.id,
        type: item.type,
        data: item.data,
        position: item.position,
        style: item.style,
      };

      return saveItem;
    });

    updateProjectData(
      saveItems,
      currentZoom,
      currentCenter?.lat,
      currentCenter?.lng
    );
    toast("Project Saved", { type: "success" });
  }, [items, lines]);

  const updateProjectData = (
    saveItems: any,
    zoom: number,
    lat: number,
    lng: number
  ) => {
    Axios(auth)
      .post("/api/projects/save", {
        id: id,
        items: saveItems,
        zoom: zoom,
        mapZoom: zoom,
        lat: lat,
        lng: lng,
        lines,
      })
      .then((resp) => {
        console.log("Project Saved");
      })
      .catch((err) => {});
  };

  const onClickCancel = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const handleOnDragMarker = useCallback((event: any, itemId: string) => {
    setItems((prevItems) => {
      const selected = prevItems.find((i) => i.id === itemId);
      const diffLong = event.lngLat.lng - selected.position.long;
      const diffLat = event.lngLat.lat - selected.position.lat;
      const updatedItems = prevItems.map((item) => {
        if (item.active) {
          return {
            ...item,
            position: {
              long: item.position.long + diffLong,
              lat: item.position.lat + diffLat,
            },
          };
        }
        return item;
      });

      return updatedItems;
    });
  }, []);

  const markers = useMemo(() => {
    return items.map((item, index) => {
      return (
        <CustomMarker
          key={item.id}
          item={item}
          isLock={lock}
          setLock={setLock}
          viewport={viewport}
          onDragEnd={handleOnDragMarker}
          onClick={(markerId: string) => {
            const updatedList = items.map((item) => {
              if (item.id === markerId) {
                return { ...item, active: !item.active };
              } else {
                return { ...item };
              }
            });
            if (item.type === "text") {
              setPopupInfo(item);
            }

            setItems(updatedList);
          }}
          onChange={(newText: string) => {
            setItems((prevItems) => {
              let newItems = [...prevItems];
              newItems[index].data.text = newText;
              return newItems;
            });
          }}
          onCopy={(item, itemTransform) => {
            setItems((prevItems) => {
              let newItems = [...prevItems];
              const prevItem = newItems.find((_item) => _item.id === item.id);
              if (prevItem) {
                prevItem.active = false;
              }
              newItems.push({
                ...item,
                id: uuidv4(),
                style: itemTransform,
                position: {
                  long: item.position.long + 0.00007,
                  lat: item.position.lat - 0.00007,
                },
                active: true,
              });
              return newItems;
            });
          }}
        />
      );
    });
  }, [handleOnDragMarker, items, lock, viewport]);

  useEffect(() => {
    if (lock) {
      setItems((prev) => prev?.map((i) => ({ ...i, active: false })));
      setLines((prev) => prev?.map((i) => ({ ...i, selected: false })));
    }
  }, [lock]);

  const [mapModal, setMapModal] = useState(false);
  const [mapModalImg, setMapModalImg] = useState("");

  return (
    <SnippingTool
      mapModal={mapModal}
      setMapModal={setMapModal}
      mapModalImg={mapModalImg}
      setMapModalImg={setMapModalImg}
      projectName={project.name}
    >
      <div
        style={{ opacity: mapModalImg ? 0 : 1 }}
        className="flex flex-col gap-5 w-full pb-5"
      >
        {colorModal && (
          <SelectColor
            hideColorModal={() => showColorModal(false)}
            onColorSelect={onColorSelect}
          />
        )}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 h-full flex flex-col">
          <>
            <div className="p-5 border-b-2 border-gray-200 flex">
              <div className="flex gap-3 items-center">
                <button
                  className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                  onClick={onClickAddText}
                >
                  Tekst
                </button>
                <button
                  className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                  onClick={onClickDelete}
                >
                  Slet
                </button>
                <button
                  className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                  onClick={onClickLock}
                >
                  {lock ? "Unlock" : "Lock"}
                </button>
                {drawLine && <label>Drawing Line...</label>}
              </div>
              <div className="text-center text-2xl grow">
                {project.name || ""}
              </div>
              <div className="flex gap-3 justify-end">
                <button
                  className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                  onClick={onClickSave}
                >
                  Gem
                </button>
                <PrintProject
                  mapModal={mapModal}
                  setMapModal={setMapModal}
                  mapRef={mapRef}
                  divRef={divRef}
                  project={project}
                  setLock={setLock}
                  setViewport={setViewport}
                  setMapModalImg={setMapModalImg}
                />
                <button
                  className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                  onClick={onClickShare}
                >
                  Del
                </button>
                <button
                  className="relative flex justify-center rounded-md bg-gray-200 outline outline-1 outline-gray-400 py-2 px-3 text-sm font-semibold text-dark hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                  onClick={onClickCancel}
                >
                  luk
                </button>
              </div>
            </div>
            <div className="gap-1 grid grid-cols-[300px_1fr] flex-1">
              <div className="border-r-2 border-gray-200 py-2">
                <StickerList onClickAdd={onClickAddSign} />
              </div>
              <div className="overflow-auto h-full relative">
                <div
                  className="p-0 absolute border border-solid border-gray-500 w-full"
                  id="project-panel"
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                >
                  <div
                    onKeyDown={(e) => {
                      if (e.key === "Delete") {
                        onClickDelete();
                      }
                    }}
                    ref={divRef}
                    style={{
                      width: "100%",
                      height: "calc( 100vh - 175px)",
                    }}
                  >
                    <div
                      id="maplogo"
                      style={{
                        position: "absolute",
                        top: "10px",
                        zIndex: 100,
                        left: "10px",
                        display: "none",
                      }}
                    >
                      <img
                        src={require("../assets/logo-map.png")}
                        style={{ width: "50%", height: "50%" }}
                      />
                    </div>

                    <Map
                      ref={mapRef}
                      initialViewState={{
                        longitude: 10.915721,
                        latitude: 55.897377,
                        zoom: 6.5,
                      }}
                      zoom={viewport.zoom}
                      mapStyle={mapStyle}
                      onZoom={(e) => {
                        const zoom = e.viewState.zoom;
                        setViewport((prev) => {
                          return { ...prev, zoom };
                        });
                      }}
                      onRotate={(e) => {
                        const bearing = e.viewState.bearing;
                        setViewport((prev) => {
                          return { ...prev, bearing };
                        });
                      }}
                      mapboxAccessToken={config.mapBoxToken}
                      trackResize={true}
                      styleDiffing={true}
                      onClick={(event) => {
                        setClickCoordinates(event.lngLat);
                        const features = mapRef.current.queryRenderedFeatures(
                          event.point
                        );

                        if (features.length > 0) {
                          const clickedFeature = features[0];
                          const selectedFeatureId =
                            clickedFeature?.source.split("-")[1];

                          setLines(
                            lines.map((line) =>
                              line.id == selectedFeatureId
                                ? { ...line, selected: !line.selected }
                                : { ...line, selected: false }
                            )
                          );
                          setLock(false);
                        } else {
                          setLines(
                            lines.map((line) => ({
                              ...line,
                              selected: false,
                            }))
                          );
                        }
                      }}
                      onMouseMove={(event) => setHoverCoordinates(event.lngLat)}
                      onDblClick={(event) => {
                        if (
                          clickCoordinates?.lng !== null &&
                          clickCoordinates?.lat !== null
                        ) {
                          event.preventDefault();
                          setClickCoordinates({ lng: null, lat: null });
                        }
                      }}
                    >
                      <MapLineDrawer
                        hideControls={lock}
                        lines={lines}
                        setLines={setLines}
                        viewport={viewport}
                        clickCoordinates={clickCoordinates}
                        hoverCoordinates={hoverCoordinates}
                      />
                      <GeocoderControl
                        marker={false}
                        mapboxAccessToken={config.mapBoxToken}
                        position="top-left"
                      />
                      <div
                        style={{ position: "absolute", top: 8, right: 8 }}
                        id={"ChangeBaseMap"}
                      >
                        <button
                          style={buttonStyles}
                          onClick={() => {
                            if (mapStyle === SatelliteView) {
                              setMapStyle(NormalView);
                            } else {
                              setMapStyle(SatelliteView);
                            }
                          }}
                        >
                          {mapStyle === SatelliteView ? (
                            <img
                              alt="users-icon"
                              src={require("../assets/treasure-map.png")}
                            />
                          ) : (
                            <img
                              alt="users-icon"
                              src={require("../assets/treasure-mapS.png")}
                            />
                          )}
                        </button>
                      </div>
                      {markers}
                      {popupInfo && (
                        <Popup
                          anchor="top"
                          longitude={Number(popupInfo.position.long)}
                          latitude={Number(popupInfo.position.lat)}
                          onClose={() => setPopupInfo(null)}
                        >
                          <textarea
                            defaultValue={popupInfo.data.text}
                            onChange={(e) => {
                              const newText = e.target.value;
                              setItems((prevItems) => {
                                let newItems = [...prevItems];
                                const targetItemIndex = newItems.findIndex(
                                  (_item) => _item.id === popupInfo.id
                                );
                                newItems[targetItemIndex].data.text = newText;
                                return newItems;
                              });
                            }}
                          ></textarea>
                        </Popup>
                      )}
                    </Map>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </SnippingTool>
  );
}

export default ProjectDetail;
