import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import SideMenu from "./SideMenu";
import AuthContext from "../AuthContext";

function Layout() {
  const authContext = useContext(AuthContext);
  const isAdmin = authContext.userData.role === "admin";
  return (
    <>
      <div className="md:h-16 fixed top-0 w-full z-40">
        <Header />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-[200px_1fr] bg-gray-100">
        {isAdmin && (
          <div className="h-screen sticky top-0 hidden lg:flex pt-16">
            <SideMenu />
          </div>
        )}
        <div className={"flex justify-center min-h-screen pt-20 px-5"}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Layout;
