import React, { useState, useRef, useMemo } from "react";
import { Marker } from "react-map-gl";
import Moveable, { MoveableManagerInterface, Renderer } from "react-moveable";
import Pin from "./pin";

function CustomMarker(props: any) {
  const { item, viewport, isLock, onDragEnd, onClick, onCopy, setLock } = props;
  const targetRef = useRef<any>();
  const [first, setFirst] = useState(false);
  const TargetZoomLevel = 16;

  const Editable = {
    name: "editable",
    props: [],
    events: [],
    render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
      const rect = moveable.getRect();
      const { pos2 } = moveable.state;

      const EditableViewer = moveable.useCSS(
        "div",
        `
                {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    will-change: transform;
                    transform-origin: 0px 0px;
                }
                .custom-button {
                    width: 20px;
                    height: 20px;
                    transform: translate(-100%, 0%);
                    margin-bottom: 4px;
                    background: #4af;
                    border-radius: 4px;
                    appearance: none;
                    border: 0;
                    color: white;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            `
      );
      return (
        <EditableViewer key={"editable-viewer"} className={"moveable-editable"}>
          <button
            className="custom-button"
            onClick={() => onCopy(item, item.style?.width)}
          >
            <img alt="dashboard-icon" src={require("../assets/copy.png")} />
          </button>
        </EditableViewer>
      );
    },
  } as const;

  const calculateMarkerSize = (zoom: any) => {
    let markerSize = "0";
    if (zoom < 18) {
      markerSize = (((zoom - 15) / (25 - 15)) * 100).toFixed(2);
    } else if (zoom < 20) {
      markerSize = (((zoom - 15) / (25 - 15)) * (130 + zoom)).toFixed(2);
    } else {
      markerSize = (((zoom - 15) / (25 - 15)) * (200 + zoom)).toFixed(2);
    }
    return Number(markerSize) > 0 ? markerSize : 0;
  };

  const calculateMarkerDis = (zoom: any) =>
    zoom > TargetZoomLevel ? "block" : "none";

  const className =
    viewport.zoom > TargetZoomLevel ? "display-block" : "display-none";

  const markerFill = useMemo(() => {
    switch (item.type) {
      case "sign":
        return "#b00";
      case "text":
        return "#FFFF00";
      default:
        return "#b00";
    }
  }, [item]);

  return (
    <Marker
      latitude={item?.position?.lat || 32}
      longitude={item?.position?.long || 32}
      draggable={item.active}
      onDragEnd={(e) => onDragEnd(e, item.id)}
      onClick={(e) => {
        e.originalEvent.stopPropagation();
        onClick(item.id);
      }}
      onDrag={(e) => onDragEnd(e, item.id)}
    >
      {viewport.zoom > TargetZoomLevel ? (
        <div key={item.id}>
          {item.type === "sign" && (
            <div
              style={{
                display: calculateMarkerDis(viewport.zoom),
              }}
            >
              <img
                ref={targetRef}
                src={item.data.image}
                style={{
                  width: calculateMarkerSize(viewport.zoom) + "px",
                  height: calculateMarkerSize(viewport.zoom) + "%",
                  transform: item.style?.transform,
                }}
                alt={item.data.name}
                onClick={() => setLock(false)}
              />
            </div>
          )}
          {item.type === "text" && (
            <div onClick={() => setLock(false)}>
              <p
                ref={targetRef}
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "8px",
                  backgroundColor: "yellow",
                }}
              >
                {item.data.text}
              </p>
            </div>
          )}
        </div>
      ) : (
        <Pin fill={markerFill} />
      )}
      <Moveable
        target={item.active && targetRef.current}
        draggable={false}
        key={`${calculateMarkerSize(viewport.zoom)}`}
        rotatable={!isLock}
        scalable={!isLock}
        origin={false}
        keepRatio={true}
        ables={item.active && [Editable]}
        props={{
          editable: item.active,
        }}
        rotationPosition={"top"}
        onRotate={(e) => {
          e.target.style.transform = e.drag.transform;
          let transformString = e.drag.transform;
          let startIndex = transformString.indexOf("rotate(");
          let newTransformString = transformString.substring(startIndex);
          item.style = { transform: newTransformString };
        }}
        onScale={(e) => {
          if (!first) {
            e.target.style.transform = e.drag.transform;
            let transformString = e.drag.transform;
            let startIndex = transformString.includes("rotate(")
              ? transformString.indexOf("rotate(")
              : transformString.indexOf("scale(");
            let newTransformString = transformString.substring(startIndex);
            item.style = {
              transform: newTransformString.includes("rotate")
                ? newTransformString
                : newTransformString + "rotate(1deg)",
            };
          }
        }}
        className={className}
      />
    </Marker>
  );
}
export default CustomMarker;
