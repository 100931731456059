import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useMemo, useState } from "react";

const SignsModal = ({ open, signs, onClose, onSelect }) => {
  const [search, setSearch] = useState("");

  const filteredSigns = useMemo(() => {
    return signs?.filter((sign) =>
      sign.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, signs]);


  const handleSelect = (sign) => {
    onSelect(sign);
    onClose();
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                <h2 className="text-center font-medium text-2xl py-2">
                  Select Sticker
                </h2>
                <input
                  type="text"
                  name="search"
                  id="txt-search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-[92%] mx-auto p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mb-2"
                  placeholder="Search ..."
                />
                <div className="max-h-[500px] overflow-auto">
                  {filteredSigns?.map((sign) => (
                    <div
                      onClick={() => handleSelect(sign)}
                      className="flex flex-row items-center px-4 py-2 gap-2 cursor-pointer hover:bg-slate-100 border-b"
                    >
                      <img
                        src={sign.image}
                        alt={sign?.name}
                        className="h-14 w-14 object-contain"
                      />
                      <p>{sign.name}</p>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SignsModal;
