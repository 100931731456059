import axios from "axios";

const Axios = (auth) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:4000";
  axios.defaults.baseURL = baseUrl;

  if (auth && auth.user && auth.user.token)
    axios.defaults.headers.common["Authorization"] = auth.user.token;

  return axios;
};

export default Axios;
